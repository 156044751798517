import { runtimeEnv, useSafeLogout } from '@worknet/app-core';
import { WsClientToServerEvents, WsServerToClientEvents } from '@worknet/models';
import { io, Socket, SocketOptions } from 'socket.io-client';

export type TypeSocket = Socket<WsServerToClientEvents, WsClientToServerEvents>;

export function createSocketIo(
  authHeaders: Record<string, string>,
  getToken?: () => Promise<string>
): TypeSocket {
  const wsBaseUrl = `${runtimeEnv.apiBaseUrl}/ws`;
  const url = new URL(`${wsBaseUrl}/socket.io`);
  const logout = useSafeLogout();

  const onAuth: SocketOptions['auth'] = async (setAuthPayload) => {
    const token = await getToken?.().catch(logout);
    setAuthPayload(token ? { authorization: `Bearer ${token}` } : {});
  };

  return io(url.origin, {
    withCredentials: true,
    path: url.pathname,
    extraHeaders: authHeaders,
    auth: onAuth,
  });
}
