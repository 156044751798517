import { Center, Text, useToast } from '@chakra-ui/react';
import {
  CustomLoginPage,
  CustomSSOLoginPage,
  showErrorToast,
  useSafeLogout,
  useWidgetContextParams,
} from '@worknet/app-core';
import { useEffect } from 'react';

import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout.tsx';
import worknetWhiteLogo from '~/shared/assets/worknetWhiteLogo.png';

export function CustomLoginComponent() {
  const { errorMessage, requiredSSOName, widgetAppName, behaviorType } = useWidgetContextParams();
  const toast = useToast();
  const logout = useSafeLogout();

  useEffect(() => {
    if (errorMessage && behaviorType === 'bothAnonAuth') {
      showErrorToast(toast, errorMessage, { title: 'Error' });
      logout();
    }
  }, [errorMessage, behaviorType, logout]);

  return (
    <MainFlowLayout>
      <Center gap="10px" flexDir="column" flex={1}>
        <Center>
          {requiredSSOName && widgetAppName ? (
            <CustomSSOLoginPage appName={widgetAppName} connection={requiredSSOName} />
          ) : (
            <CustomLoginPage logoUrl={worknetWhiteLogo} isLoginWithPopup />
          )}
        </Center>

        {errorMessage && (
          <Text color="red" textAlign={'center'} pt={'20px'}>
            Error: {errorMessage}
          </Text>
        )}
      </Center>
    </MainFlowLayout>
  );
}
