import { useAuth0 } from '@auth0/auth0-react';
import { runtimeEnv, useWidgetContextParams } from '@worknet/app-core';
import { useNavigate } from 'react-router-dom';

export function useSafeLogout() {
  const { logout } = useAuth0();
  const { behaviorType } = useWidgetContextParams();
  const navigate = useNavigate();

  return function safeLogout() {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = `https://${runtimeEnv.auth0Domain}/v2/logout?client_id=${runtimeEnv.auth0ClientId}`;
    document.body.appendChild(iframe);

    logout({ openUrl: false }).then(() => setTimeout(() => document.body.removeChild(iframe), 500));

    if (behaviorType === 'bothAnonAuth') {
      navigate(`/anon-iframe${location.search}`);
    }
  };
}
