const qs = new URLSearchParams(location.search);

export const getHeaders = () => {
  const widgetKey = qs.get('widgetKey');
  return {
    ...(widgetKey ? { 'x-wn-widget-key': widgetKey } : {}),
  };
};

export const getCustomContext = () => {
  const customContext = qs.get('customContext');
  return customContext ? JSON.parse(customContext) : undefined;
};

export const getGroupingId = () => {
  const groupingId = qs.get('groupingId');
  return groupingId || undefined;
};

export const getNotificationBody = () => {
  const title = qs.get('notificationTitle');
  const prompt = qs.get('notificationPrompt');
  return title && prompt ? { title, prompt } : undefined;
};
