import { lazy, Suspense } from 'react';
import { Route, Routes as _Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import BouncingLoader from '~/shared/components/ds/BouncingLoader';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import Zendesk from '~/zendesk'; // resize for auth screen not working with `lazy(() => import('~/zendesk'));`
import RedirectWithParams from '~/shared/components/ds/RedirectWithParams.tsx';
import NotFound from '~/shared/components/ds/NotFound.tsx';

import AuthenticatedOutlet from './AuthenticatedOutlet';

const Salesforce = lazy(() => import('~/salesforce'));
const Iframe = lazy(() => import('~/iframe'));
const AnonIframe = lazy(() => import('~/anonIframe'));

const Loading = () => (
  <MainFlowLayout>
    <BouncingLoader />
  </MainFlowLayout>
);

export default function Routes() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <_Routes>
        <Route path="anon-iframe" element={<AnonIframe />} />
        <Route path="/" Component={() => <AuthenticatedOutlet />}>
          <Route path="zendesk" element={<Zendesk />} />
          <Route path="salesforce" element={<Salesforce />} />
          <Route path="iframe" element={<Iframe />} />
          {/* required for manual sign-in selection from header menu items */}
          <Route path="sign-in" element={<RedirectWithParams to="iframe" />} />
        </Route>
        <Route path="/*" Component={() => <NotFound />} />
      </_Routes>
    </Suspense>
  );
}
