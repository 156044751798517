import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  baseUrl,
  datadogInit,
  getAuth0RedirectUrl,
  runtimeEnv,
  WorknetAuthProviderWithRedirect,
} from '@worknet/app-core';

import ThemedChakraProvider from '~/shared/components/ThemedChakraProvider';

import Routes from './Routes';

datadogInit('copilot-site');

export default function App() {
  const client = new QueryClient();
  return (
    <StrictMode>
      <ThemedChakraProvider>
        <QueryClientProvider client={client}>
          <BrowserRouter basename={baseUrl}>
            <WorknetAuthProviderWithRedirect
              domain={runtimeEnv.auth0Domain}
              clientId={runtimeEnv.auth0ClientId}
              authorizationParams={{
                audience: runtimeEnv.auth0Audience,
                redirect_uri: getAuth0RedirectUrl(),
              }}
              // local storage is used to allow cached tokens be accessible within multiple tabs
              cacheLocation="localstorage"
              // the time gap in seconds between the moment the token is issued and the moment it expires
              // sometimes needed to mitigate user local time de-synchronization
              leeway={60}
              // use refresh token rotation as main way to obtain new access tokens silently
              useRefreshTokens
              // if standard refresh token rotation fails, use hidden iframe to obtain new access tokens
              useRefreshTokensFallback
              // needs to overcome iframe security limitations
              // in case when session storage is limited during auth flow
              useCookiesForTransactions
            >
              <Routes />
            </WorknetAuthProviderWithRedirect>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemedChakraProvider>
    </StrictMode>
  );
}
