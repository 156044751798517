import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useSafeLogout, useWidgetContextParams } from '@worknet/app-core';

import { MenuItemProps } from '~/shared/components/ds/Menu.tsx';
import useChatStore from '~/shared/utils/useChatStore.ts';
import LogoutIcon from '~/shared/components/ds2/assets/Logout.svg?react';
import LoginIcon from '~/shared/components/ds2/assets/Login.svg?react';

export const useMenuItems = (customMenuItems?: MenuItemProps[]) => {
  const { behaviorType } = useWidgetContextParams();
  const { hostPlatformContext } = useChatStore();
  const navigate = useNavigate();
  const logout = useSafeLogout();

  return useMemo<MenuItemProps[]>(() => {
    const items = customMenuItems ? [...customMenuItems] : [];

    if (hostPlatformContext?.user) {
      items.push({
        text: 'Sign out',
        IconSvg: LogoutIcon,
        onClick: () => {
          logout();
        },
      });
    } else {
      if (behaviorType === 'bothAnonAuth') {
        items.push({
          text: 'Sign in',
          IconSvg: LoginIcon,
          onClick: () => {
            logout();
            navigate(`/sign-in${location.search}`);
          },
        });
      }
    }

    return items;
  }, [customMenuItems, hostPlatformContext, logout, behaviorType, navigate]);
};
