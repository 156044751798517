import { Box, Text } from '@chakra-ui/react';

import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout.tsx';

export default function NotFound() {
  return (
    <MainFlowLayout alignItems={'center'} justifyContent={'center'}>
      <Box textAlign={'center'} flexDirection={'column'} width={'260px'}>
        <Box display={'inline-block'}>
          <img
            src="https://cdn.prod.website-files.com/static/page-not-found.211a85e40c.svg"
            alt="404"
          />
        </Box>
        <Text fontSize={'44px'} fontWeight={600} lineHeight={1.2} py={'20px'}>
          Page Not Found
        </Text>
        <div>The page you are looking for doesn&apos;t exist or has been moved</div>
      </Box>
    </MainFlowLayout>
  );
}
