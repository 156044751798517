/** @jsxImportSource react */
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  ButtonProps,
  Input,
  Stack,
  Text,
  Image,
  keyframes,
  StackProps,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Auth0Connection, Auth0ConnectionsResponse } from '@worknet/models';
import { useCallback, useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { runtimeEnv } from '../utils';
import { useApiMutationWithToast, useApiQueryWithToast } from '../api';
import { useWidgetContextParams } from '../hooks';

import LoginPageLoader from './LoginPageLoader';

const icons = import.meta.glob('../assets/auth0/*.svg', { eager: true });

const slideLeft = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-200%)
    }
`;

const primaryColor = '#3449ba';
const buttonsAndInputHeight = { base: '42px', md: '52px' };

type SSOInfo = { criteria?: string } | undefined;
type AnimationDirection = 'left' | 'right' | undefined;

interface CustomLoginPageProps {
  logoUrl: string;
  isLoginWithPopup?: boolean;
  samlBaseRedirectUrl?: string;
}

interface SectionContainerProps extends StackProps {
  animationDirection: AnimationDirection;
  subTitle: string;
}

const SectionContainer = ({ animationDirection, subTitle, ...rest }: SectionContainerProps) => {
  const slideAnimation =
    animationDirection &&
    `${slideLeft} 0.25s linear forwards ${animationDirection === 'right' ? 'reverse' : ''}`;

  return (
    <Stack key={animationDirection} w="full" h="full" animation={slideAnimation} {...rest}>
      <Text mb={{ base: '10px', md: '12px' }} textAlign="center" color="#1e212a" fontSize="14px">
        {subTitle}
      </Text>
      {rest.children}
    </Stack>
  );
};

const CustomButton = ({ isDisabled, ...rest }: ButtonProps) => (
  <Button
    gap="16px"
    h={buttonsAndInputHeight}
    bg="white"
    color="#1e212a"
    border="1px solid rgb(201, 202, 206)"
    borderRadius="3px"
    fontWeight={400}
    fontSize="16px"
    isDisabled={isDisabled}
    _hover={isDisabled ? undefined : { bg: primaryColor, color: 'white' }}
    {...rest}
  />
);

export const CustomLoginPage = ({
  logoUrl,
  samlBaseRedirectUrl,
  isLoginWithPopup,
}: CustomLoginPageProps) => {
  const { behaviorType } = useWidgetContextParams();
  const [ssoInfo, setSsoInfo] = useState<SSOInfo>();
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [animationDirection, setAnimationDirection] = useState<AnimationDirection>();

  const { loginWithPopup, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  const { data: connections } = useApiQueryWithToast({
    queryKey: ['authConnections'],
    api: 'public',
    apiQueryFn: async (apiClient) =>
      apiClient.get<Auth0ConnectionsResponse>(`/auth0/connections/${runtimeEnv.auth0ClientId}`),
  });

  const login = useCallback(async (connection: string) => {
    if (connection === 'anonymous') {
      navigate(`/anon-iframe${location.search}`);
    } else if (samlBaseRedirectUrl) {
      window.location.href = `${samlBaseRedirectUrl}?connection=${connection}`;
    } else if (isLoginWithPopup) {
      await loginWithPopup({ authorizationParams: { connection } });
    } else {
      await loginWithRedirect({ authorizationParams: { connection } });
    }
  }, []);

  const { mutateAsync } = useApiMutationWithToast({
    options: {
      api: 'public',
      mutationKey: ['sso-connection'],
      toastProps: { duration: 7500 },
      apiMutationFn: (api) =>
        api.post<Auth0Connection>('/auth0/sso-connection', {
          criteria: ssoInfo?.criteria,
          clientId: runtimeEnv.auth0ClientId,
        }),
    },
  });

  const onSSOInfoChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    setSsoInfo({ criteria: e.target.value.trim() });
  }, []);

  const onSSOLogin = useCallback(() => {
    setSsoInfo({});
    setAnimationDirection('left');
  }, []);

  const onBack = useCallback(() => {
    setSsoInfo(undefined);
    setAnimationDirection('right');
  }, []);

  const onSSOContinue = useCallback(async () => {
    const data = await mutateAsync();
    if (data) {
      await login(data.connection);
    } else {
      setIsInvalid(true);
    }
  }, [mutateAsync]);

  useEffect(() => {
    if (isAuthenticated && !samlBaseRedirectUrl) {
      navigate('/');
    }
  }, [isLoading, isAuthenticated, navigate]);

  const filteredConnections = useMemo(
    () => (connections || []).filter((c) => c?.displayName !== 'Salesforce'),
    [connections]
  );

  if (!connections) {
    return <LoginPageLoader />;
  }

  return (
    <Stack
      w={{ base: '320px', md: '400px' }}
      px={{ base: '44px', md: '60px' }}
      py={{ base: '20px', md: '60px' }}
      gap={{ base: '14px', md: '20px' }}
      alignItems="center"
      bg="white"
      border="1px"
      borderColor="gray.500"
      borderRadius="6px"
      m="auto"
      boxShadow=" rgba(100, 100, 111, 0.2) 0px 7px 15px 0px"
      overflow="hidden"
    >
      <Image src={logoUrl} boxSize={{ base: '40px', md: '55px' }} />
      <Text fontSize={{ base: '22px', md: '24px' }} color="#1e212a">
        Sign In To Worknet
      </Text>

      <Flex w="full" h="full" pos="relative">
        <SectionContainer
          animationDirection={animationDirection}
          subTitle="Please choose one of the following options"
        >
          <Stack>
            {filteredConnections.map(({ connection, displayName, id, strategy }) => {
              const iconKey = Object.keys(icons).find((key) => key.endsWith(`${strategy}.svg`));
              const iconUrl =
                iconKey && (icons[iconKey] as { default: string } | undefined)?.default;

              return (
                <CustomButton justifyContent="start" key={id} onClick={() => login(connection)}>
                  <Image src={iconUrl} boxSize="20px" />
                  {displayName || ''}
                </CustomButton>
              );
            })}
          </Stack>
          {behaviorType === 'bothAnonAuth' && (
            <CustomButton mt="14px" onClick={() => login('anonymous')}>
              Sign in anonymously
            </CustomButton>
          )}

          <CustomButton mt="14px" onClick={onSSOLogin}>
            Sign in with SSO
          </CustomButton>
        </SectionContainer>

        <SectionContainer
          subTitle="Sign in with SSO"
          animationDirection={animationDirection}
          pos="absolute"
          left="200%"
        >
          <Stack h="full">
            <FormControl isInvalid={isInvalid}>
              <Input
                h={buttonsAndInputHeight}
                border="1px solid rgb(201, 202, 206)"
                _focusVisible={isInvalid ? { boxShadow: `0 0 0 1px ${primaryColor}` } : undefined}
                placeholder="Enter email or SSO domain"
                onChange={onSSOInfoChange}
                fontSize={{ base: '14px', md: '16px' }}
              />
              <FormErrorMessage justifyContent="center">Connection not found</FormErrorMessage>
            </FormControl>

            <Stack gap="0.5rem" mt="0.5rem" w="full" justifyContent="space-between">
              <CustomButton isDisabled={!ssoInfo?.criteria} onClick={onSSOContinue}>
                Continue
              </CustomButton>
              <CustomButton border="none" _hover={{ bg: 'gray.50' }} onClick={onBack}>
                Back
              </CustomButton>
            </Stack>
          </Stack>
        </SectionContainer>
      </Flex>
    </Stack>
  );
};
