import { Box, Button, Flex, Icon, Text, useToast } from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';

import { markdownStyles } from '~/shared/utils/theme';

import { copyToClipboard } from '../../utils/copyToClipboard';

import CopySvg from './assets/IoCopySharp.svg?react';
import BouncingLoader from './BouncingLoader';
import Markdown from './Markdown';

type Props = {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featureOverrides?: any;
};

function IconButton({
  title,
  svg,
  onClick,
}: {
  title: string;
  svg: typeof CopySvg;
  onClick?: () => void;
}) {
  return (
    <Button
      title={title}
      onClick={onClick}
      variant="unstyled"
      display="flex"
      alignItems="center"
      color="#545F715C"
      _hover={{ color: '#545F7199' }}
      p={0}
      minW="auto"
      h="auto"
      fontSize="title"
      lineHeight="16px"
    >
      <Icon as={svg} boxSize="15px" />
    </Button>
  );
}

const Typewriter = ({ text }: { text: string }) => {
  const [typedText, setTypedText] = useState('');
  const typingSpeed = 10; // milliseconds

  useEffect(() => {
    if (text.length > typedText.length) {
      const timer = setTimeout(() => {
        setTypedText(text.slice(0, typedText.length + 1));
      }, typingSpeed);

      return () => clearTimeout(timer);
    }
  }, [text, typedText]);

  return <Markdown content={`${typedText} <span class="blinking-cursor">|</span>`} />;
};

export default function ServerLoadingMessage({ message, featureOverrides }: Props) {
  const toast = useToast();
  const contentRef = useRef<HTMLDivElement>(null);

  const handleCopy = async () => {
    if (message && contentRef.current) {
      try {
        const el = document.querySelector('.worknet-markdown') as HTMLElement | null;
        if (!el) {
          return;
        }

        if (featureOverrides?.copyToClipboard) {
          await featureOverrides.copyToClipboard?.(contentRef.current, message);
        } else {
          await copyToClipboard(contentRef.current, message);
        }

        toast({
          title: 'Copied!',
          status: 'success',
          description: 'Thanks message was copied',
          isClosable: false,
          duration: 500,
        });
      } catch (error) {
        toast({
          title: 'Error',
          status: 'error',
          description: (error as Error).message,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex fontSize="chatText" flex={1} gap={2} className={markdownStyles}>
      <Flex
        direction="column"
        bg="#F2F2F2"
        borderRadius={8}
        p="10px 16px"
        gap={2}
        flex="1"
        overflowX="hidden"
      >
        <Box flex={1}>
          {message ? (
            <Flex direction="column" gap={2}>
              <Box ref={contentRef} className="worknet-markdown">
                <Typewriter text={message} />
              </Box>
              <Flex justifyContent="end">
                <IconButton
                  title="Copy to clipboard"
                  svg={CopySvg}
                  onClick={() => {
                    void handleCopy();
                  }}
                />
              </Flex>
            </Flex>
          ) : (
            <Flex align="center" gap="5px">
              <Text>Thinking</Text>
              <Box mt="1px">
                <BouncingLoader variant="sm" />
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
