import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useWidgetContextParams } from '@worknet/app-core';

import { CustomLoginComponent } from '~/app/components/CustomLoginComponent.tsx';
import RedirectWithParams from '~/shared/components/ds/RedirectWithParams.tsx';

export default function AuthenticatedOutlet() {
  const { behaviorType, requiredSSOName, widgetAppName } = useWidgetContextParams();
  const { isAuthenticated } = useAuth0();

  if (behaviorType === 'onlyAnon') {
    return <RedirectWithParams to="anon-iframe" />;
  }

  if (!isAuthenticated) {
    if (behaviorType === 'onlyAuth') {
      return <CustomLoginComponent />;
    }

    if (behaviorType === 'bothAnonAuth') {
      if ((requiredSSOName && widgetAppName) || location.pathname.includes('sign-in')) {
        return <CustomLoginComponent />;
      }

      return <RedirectWithParams to="anon-iframe" />;
    }
  }

  return <Outlet />;
}
