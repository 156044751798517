import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WidgetBehaviorType } from '@worknet/models';

type WidgetContextParams = {
  widgetAppName: string;
  requiredSSOName: string;
  errorMessage: string;
  behaviorType: WidgetBehaviorType;
};

export function useWidgetContextParams() {
  const { error } = useAuth0();
  const [searchParams] = useSearchParams();

  return useMemo<WidgetContextParams>(() => {
    const errorFromParams = searchParams.get('error');
    const behaviorType = (searchParams.get('behaviorType') || 'onlyAuth') as WidgetBehaviorType;
    const requiredSSOName = searchParams.get('requiredSSOName') || '';
    const widgetAppName = searchParams.get('widgetAppName') || '';

    const altErrorMessage =
      errorFromParams === 'Unauthorized' || error?.message
        ? 'Organization or user is not authorized. Try to login with the correct User and organization. If the problem persists, please contact your administrator.'
        : '';

    const errorMessage =
      error?.message && !error?.message.includes('user does not belong')
        ? error.message
        : altErrorMessage;

    return {
      widgetAppName,
      requiredSSOName,
      errorMessage,
      behaviorType,
    };
  }, [error, searchParams]);
}
