/** @jsxImportSource react */
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo, useState } from 'react';
import { Button, Checkbox, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useWidgetContextParams } from '../hooks';

const icons = import.meta.glob('../assets/auth0/*.svg', { eager: true });

export const CustomSSOLoginPage = ({
  appName,
  connection,
}: {
  appName: string;
  connection: string;
}) => {
  const { loginWithPopup } = useAuth0();
  const { behaviorType } = useWidgetContextParams();
  const [isAnonSelected, setAnonSelected] = useState(false);
  const navigate = useNavigate();

  const onSSOChatLogin = useCallback(async () => {
    if (isAnonSelected) {
      navigate(`/anon-iframe${location.search}`);
    } else {
      await loginWithPopup({ authorizationParams: { connection } });
    }
  }, [isAnonSelected]);

  const iconBubblesUrl = useMemo(() => {
    const iconKey = Object.keys(icons).find((key) => key.endsWith('bubbles.svg'));
    return iconKey && (icons[iconKey] as { default: string } | undefined)?.default;
  }, []);

  return (
    <Stack
      w="380px"
      h="630px"
      py="140px"
      gap="14px"
      alignItems="center"
      bg="white"
      m="auto"
      overflow="hidden"
    >
      <Image src={iconBubblesUrl} boxSize="110px" />
      <Text
        fontSize="18px"
        color="#000"
        lineHeight="22px"
        textAlign="center"
        mt="40px"
        fontWeight={600}
      >
        Hi 👋, welcome to
        <br />
        {appName || 'Smart Assistance'}
      </Text>

      <Flex w="full" h="full" pos="relative">
        <Stack w="full" h="full" alignItems="center">
          <Text
            w="240px"
            my="10px"
            textAlign="center"
            color="#000"
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
          >
            Start a chat with your AI assistant for expert knowledge and seamless support, anytime
            you need it!
          </Text>
          <Button mt="14px" gap="8px" h="35px" w="110px" onClick={onSSOChatLogin} variant="primary">
            Start a chat
          </Button>
          {behaviorType === 'bothAnonAuth' && (
            <Checkbox isChecked={isAnonSelected} onChange={() => setAnonSelected(!isAnonSelected)}>
              <Text my="10px" color="8c8c8c" fontSize="12px">
                start anonymously
              </Text>
            </Checkbox>
          )}
        </Stack>
      </Flex>
    </Stack>
  );
};
